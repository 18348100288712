<template>
  <v-app id="login" class="primary">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4 lg4>
            <v-card class="elevation-1 pa-3">
              <v-card-text>
                <div class="layout column align-center">
                  <img
                    src="./../assets/images/logo.png"
                    class="logo"
                    alt="InsightzClub"
                  />
                </div>
                <v-form>
                  <v-text-field
                    append-icon="person"
                    label="E-mail"
                    type="text"
                    v-model="loginDto.email"
                    required
                    v-validate="'required|email'"
                    data-vv-name="email"
                    :error-messages="errors.collect('email')"
                    v-on:click="resetError()"
                    autocomplete
                  />
                  <v-text-field
                    append-icon="lock"
                    label="Password"
                    type="password"
                    v-model="loginDto.password"
                    required
                    v-validate="'required|min:8'"
                    data-vv-name="password"
                    :error-messages="errors.collect('password')"
                    v-on:click="resetError()"
                    v-on:keyup.enter="login"
                    @keyup.enter="login"
                    autocomplete
                  />
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn class="mr-3" color="primary" @click="login">Login</v-btn>
                <v-btn outlined color="primary" @click="goToRegister"
                  >Register</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn
                  class="text-right"
                  text
                  color="primary"
                  @click="forgotPassword"
                  >Reset Password</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { AuthenticationService } from "./../services/AuthenticationService";

const authenticationService = new AuthenticationService();

export default {
  data() {
    return {
      loading: false,
      errorMessage: "",
      failed: false,
      loginDto: {},
    };
  },

  mounted() {
    this.redirectToDashboard()
  },

  methods: {
    redirectToDashboard() {
      if (this.$route.query && this.$route.query.user && this.$route.query.password) {
        let data = {
          type: 'ifeed',
          email: this.$route.query.user,
          password: this.$route.query.password
        }

        this.$setLoader();
          authenticationService.login(data).then(r => {
            this.$store.state.buyerUser = null
            window.$cookies.set("izcToken", "");
            this.$clearLoader();

            this.$store.state.buyerUser = r.user
            window.$cookies.set("izcToken", r.token);

            this.$router.push("/dashboard");
          });

      }
    },

    resetError() {
      this.failed ? (this.failed = false) : "";
    },

    login() {
      this.$validator.validateAll().then(() => {
        if (!this.errors.any()) {
          this.$setLoader();
          authenticationService.login(this.loginDto).then(r => {
            this.$clearLoader();

            this.$store.state.buyerUser = r.user
            window.$cookies.set("izcToken", r.token);

            this.$router.push("/dashboard");
          });
        }
      });
    },

    forgotPassword() {
      this.$router.push("/forgotPassword");
    },
    
    goToRegister() {
      this.$router.push("/census/registration");
    },
  },
};
</script>

<style scoped lang="css">
.logo {
  height: auto;
  width: 200px;
  margin-top: 25px;
  margin-bottom: 25px;
}
#login {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
}
</style>
